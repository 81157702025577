<template>
    <v-expansion-panels v-model="panel">
        <v-expansion-panel>
            <v-expansion-panel-header color="#b5e61d" class="font-weight-bold white--text">
                ▼採用情報
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 py-3">
                <v-container>
                    <v-row justify='center' class="mb-5">
                        <v-col cols="12" class="font-weight-bold">
                            以下に一つでも当てはまる方は、お気軽にご応募ください。
                        </v-col>
                        <v-col cols="12" class="mb-4" style="border-top: 3px solid #b5e61d; border-bottom: 3px solid #b5e61d">
                            <ul>
                                <li class="my-3">成長の見込める業務を探している</li>
                                <li class="my-3">新設の会社で新しく挑戦したい</li>
                                <li class="my-3">実力に見合った給料を求めている</li>
                                <li class="my-3">長期的に働きたい</li>
                                <li class="my-3">IT業界で挫けた経験があるが、もう一度挑戦したい</li>
                                <li class="my-3">IT業界で成長したい</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">雇用区分</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="my-2">正社員</li>
                                <li class="my-2">契約社員 試用期間：有（3ヵ月）</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">募集職種</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="my-2">システムエンジニア</li>
                                <li class="my-2">インフラエンジニア</li>
                                <li class="my-2">プログラマー</li>
                                <li class="my-2">テストエンジニア</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">業務内容</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="font-weight-bold">システム / 開発エンジニア・プログラマー・テストエンジニア</li>
                                <li class="my-2">お客様先で設計・開発・テスト・保守の各フェーズに参画</li>
                                <li class="my-2"><br></li>
                                <li class="font-weight-bold">インフラエンジニア</li>
                                <li class="my-2">お客様先でネットワーク・サーバーの構築</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">応募資格</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="my-2">学歴不問 / 未経験OK</li>
                                <li class="my-2">以下条件に該当する方は尚良</li>
                                <li class="my-2">・Java / C++ / Python / JavaScript / PHP / SQL / AWSの経験</li>
                                <li class="my-2">・フレームワーク使用経験</li>
                                <li class="my-2">・Java開発経験</li>
                                <li class="my-2">・AWS開発経験</li>
                                <li class="my-2">・CCNA開発経験</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">勤務地</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="my-2">お客様先の現場</li>
                                <li class="my-2">本社：北海道札幌市中央区北4条西11丁目40番1号ヒルトップ札幌5F（最寄駅：札幌駅、桑園駅、西11丁目駅）</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">勤務時間</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="my-2">就業条件に沿ったフルタイム勤務（プロジェクト先によって異なる：実働8.0h)</li>
                                <li class="my-2">月残業平均 0～20時間</li>
                                <li class="my-2">※残業手当あり</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">給与</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="my-2">月給 230,000円 ～</li>
                                <li class="my-2">※経験・能力を考慮して決定</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">休日休暇</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="my-2">年間休日125日(土日祝)</li>
                                <li class="my-2">夏季休暇</li>
                                <li class="my-2">年末年始休暇</li>
                                <li class="my-2">有給休暇</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row justify='center' class="my-4">
                        <v-col cols='2' align-self="center" class="title text-center text-body-1">応募方法</v-col>
                        <v-col cols='10' class="content text-body-2">
                            <ul>
                                <li class="my-2">下記の入力フォームからご応募ください。</li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-container>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    data() {
        return {
            panel: 0,
        }
    },
    methods: {}
}
</script>

<style scoped>
.cell {
  border: solid;
  border-top: none;
}

.cell_top {
  border: solid;
}

.remove_border-right {
  border-right: none;
}

.dialog_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fade-enter-active {
  transition: opacity 2s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-active{
    transition: opacity 2s;
    opacity: 1;
}

.fade-leave-to {
    opacity: 0;
}

.title {
    font-weight: 700;
}

.content {
    border-left: solid 2px silver;
}

.content ul {
    list-style: none;
}
</style>