<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header color="#b5e61d" class="font-weight-bold white--text">
                ▼企業様向けのお問い合わせ
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 py-3">
                <v-container>
                    <v-row justify="content">
                        <v-col cols="12" class="font-weight-bold text-center">
                            下記の入力フォームから送信してください。
                        </v-col>
                        <v-col cols="12" class="text-center">
                            順次内容を確認の上、担当者より返信させていただきます。
                        </v-col>
                        <v-col cols="12" class="text-center">
                            ※内容により回答にお時間をいただく場合がございます。
                        </v-col>
                    </v-row>
                </v-container>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {}
}
</script>

<style scoped>
.cell {
  border: solid;
  border-top: none;
}

.cell_top {
  border: solid;
}

.remove_border-right {
  border-right: none;
}

.dialog_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fade-enter-active {
  transition: opacity 2s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-active{
    transition: opacity 2s;
    opacity: 1;
}

.fade-leave-to {
    opacity: 0;
}

.title {
    font-weight: 700;
}

.content {
    border-left: solid 2px silver;
}

.content ul {
    list-style: none;
}
</style>