<template>
  <div class="top">
    <v-container fluid>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" class="text-h5 text-center">【代表挨拶】</v-col>
        <v-col cols="12" md="9">
          <v-img
            src="@/assets/greeting.png"
            width="100%"
          />
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" class="text-h5 text-center">【業務紹介】</v-col>
        <v-col cols="12" md="10" class="text-body-1 text-left">
          システム開発におけるプロセスと品質の改善を目的として、多くのテスト経験で蓄積したテスト適性に優れたテストエンジニアが多く構成されております。<br>
          我々が繰り出す最適なテストプランにはインシデント管理・コスト/スケジュール管理を徹底したソフトウェアテストサービスを提供しています。<br>
          また、システム開発・インフラサービス・ITアウトソーシングなどインターネットを中心とした情報技術の提供をしております。
        </v-col>
        <v-col cols="12" v-if="!$vuetify.breakpoint.mobile">
          <table class="mx-auto">
            <tbody>
              <tr>
                <td class="col1 category" rowspan="3">【テスト】</td>
                <td class="col2">◆テストマネジメント</td>
                <td class="col7">テストの計画/見積やモニタリング、コントロール、リスクヘッジといった品質保証における製品の品質プランを高水準でご提供</td>
              </tr>
              <tr>
                <td class="col2">◆テストエンジニア</td>
                <td class="col7">
                  ・経験に基づくテスト設計、スケジュール管理をハイエンドでご提示<br>
                  ・ユーザー目線でのインシデント管理や動作テスト<br>
                  ・テストで検知した不具合のご提示<br>
                  ・品質管理/改善の実現<br>
                  ・運用テスト
                </td>
              </tr>
              <tr>
                <td class="col2">◆レビュー</td>
                <td class="col7">
                  テスト経験に基づきハイエンドで実現<br>
                  ・テストケースレビュー<br>
                  ・テストレビュー<br>
                  ・ユーザーレビュー
                </td>
              </tr>
              <tr>
                <td class="col1 category" rowspan="6">【ITアウトソーシング】</td>
                <td class="col2" rowspan="3">◆システム開発</td>
                <td class="col7">Web系システム設計・開発</td>
              </tr>
              <tr>
                <td class="col7">業務系システム設計・開発</td>
              </tr>
              <tr>
                <td class="col7">アプリケーション設計・開発</td>
              </tr>
              <tr>
                <td class="col2" rowspan="3">◆インフラサービス</td>
                <td class="col7">ネットワーク構築</td>
              </tr>
              <tr>
                <td class="col7">サーバー構築</td>
              </tr>
              <tr>
                <td class="col7">運用・保守</td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col cols="12" class="table-sp" v-else>
          <p class="text-center">【テスト】</p>
          <table class="mx-auto">
            <tbody>
              <tr><td class="text-center">◆テストマネジメント</td></tr>
              <tr><td >テストの計画/見積やモニタリング、コントロール、リスクヘッジといった品質保証における製品の品質プランを高水準でご提供</td></tr>
              <tr><td class="text-center">◆テストエンジニア</td></tr>
              <tr>
                <td>
                  ・経験に基づくテスト設計、スケジュール管理をハイエンドでご提示<br>
                  ・ユーザー目線でのインシデント管理や動作テスト<br>
                  ・テストで検知した不具合のご提示<br>
                  ・品質管理/改善の実現<br>
                  ・運用テスト
                </td>
              </tr>
              <tr><td class="text-center">◆レビュー</td></tr>
              <tr>
                <td>
                  テスト経験に基づきハイエンドで実現<br>
                  ・テストケースレビュー<br>
                  ・テストレビュー<br>
                  ・ユーザーレビュー
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <p class="text-center">【ITアウトソーシング】</p>
          <table class="mx-auto">
            <tbody>
              <tr><td class="text-center">◆システム開発</td></tr>
              <tr><td>Web系システム設計・開発</td></tr>
              <tr><td>業務系システム設計・開発</td></tr>
              <tr><td>アプリケーション設計・開発</td></tr>
              <tr><td class="text-center">◆インフラサービス</td></tr>
              <tr><td>ネットワーク構築</td></tr>
              <tr><td>サーバー構築</td></tr>
              <tr><td>運用・保守</td></tr>
            </tbody>
          </table>
        </v-col>
        <v-col cols="12" md="10" class="text-body-1 mb-4">
          ワークフローを熟知してチームの問題点となるものをピックアップし、より効果を高めるため、【Evolution（進化）とRevolution（革命/変革）】の模索/提案サイクルを行い、チームが自らのコーチ可能になることを目標としています。
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.cell {
  border: solid;
  border-top: none;
}

.cell_top {
  border: solid;
}

.category {
  writing-mode:vertical-rl;
  text-orientation: upright;
  text-align: center;
}

.category p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.remove_border-right {
  border-right: none;
}

.list {
  display: flex;
  align-items: center;
  flex-direction: column;
}

table {
  width: 80%;
  table-layout: fixed;
}

table, td, th {
  border: 2px solid black;
	border-collapse: collapse;
}

.table-sp td {
  width: 100%;
}

td {
  padding: 12px;
}

.col1 {
  width: 10%;
}
.col2 {
  width: 20%;
}
.col3 {
  width: 30%;
}
.col4 {
  width: 40%;
}
.col5 {
  width: 50%;
}
.col6 {
  width: 60%;
}
.col7 {
  width: 70%;
}
.col8 {
  width: 80%;
}
.col9 {
  width: 90%;
}
.col10 {
  width: 100%;
}
</style>