<template>
  <div class="contact">
    <v-container fluid>
        <!-- 求人情報 -->
        <v-row justify='center' class="my-7">
            <v-col cols="12" md='10'>
                <employment-information-accordion></employment-information-accordion>
            </v-col>
        </v-row>
        <!-- 企業向け問い合わせ -->
        <v-row justify='center' class="my-7">
            <v-col cols="12" md='10'>
                <for-businesses-accordion></for-businesses-accordion>
            </v-col>
        </v-row>

      <v-row justify="center" class="mt-5">
        <v-col cols="12" class="text-h5 text-center" id="form_top">【お問い合わせフォーム】</v-col>
        <v-col cols="12" md="8" class="mb-6">
          <v-alert
            :type='alert_type'
            dismissible
            v-model="alert"
            dense
          >
          {{ alert_message }}
          </v-alert>
          <v-form ref="form">
            <v-row>
              <v-col cols="5" class="text-body-1 text-center cell_top remove_border-right">
                お名前<br>
                <span style="color: red; font-size: 16px;">※必須</span>
              </v-col>
              <v-col cols="7" class="cell_top">
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="text-body-1 text-center cell remove_border-right">
                E-mail<br>
                <span style="color: red; font-size: 16px;">※必須</span>
              </v-col>
              <v-col cols="7" class="cell">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  required
                  outlined
                  dense
                  type="email"
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="text-body-1 text-center cell remove_border-right">
                お電話番号<br>
                <span style="color: red; font-size: 16px;">※必須(ハイフンなし、半角数字)</span>
              </v-col>
              <v-col cols="7" class="cell">
                <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  required
                  outlined
                  dense
                  type="tel"
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="text-body-1 text-center cell remove_border-right">
                カテゴリ<br>
                <span style="color: red; font-size: 16px;">※必須</span>
              </v-col>
              <v-col cols="7" class="cell">
                <v-select
                  outlined
                  v-model="category"
                  :items="items"
                  :rules="categoryRules"
                  dense
                  required
                ></v-select>
              </v-col>
              <v-col cols="5" class="text-body-1 text-center cell remove_border-right">
                件名
              </v-col>
              <v-col cols="7" class="cell">
                <v-text-field
                  v-model="title"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="text-body-1 text-center cell remove_border-right">
                お問い合わせ内容<br>
                <span style="color: red; font-size: 16px;">※必須</span>
              </v-col>
              <v-col cols="7" class="cell">
                <v-textarea
                  v-model="content"
                  :counter="500"
                  :rules="contentRules"
                  required
                  outlined
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row justify="start" class="my-7">
              <v-col cols="1"></v-col>
              <v-checkbox
                v-model="checkbox"
                label="個人情報の取扱規程に同意する"
                readonly
                hide-details
                @click.stop="open_dialog"
                required
                :rules="checkRules"
                style="background-color: #FFFF99"
              ></v-checkbox>
              <v-col cols="12" class="text-center pt-2" >
                <span @click.stop="open_dialog" class="pa-1" style="background-color: #FFFF99; cursor: pointer;">
                  当社の「個人情報の取扱規程に同意する」に同意した場合、送信できます。
                </span>
              </v-col>
            </v-row>

            <v-row justify="center" class="mt-6">
              <v-btn
                v-if="checkbox"
                color="success"
                large
                @click="submit"
                :loading="loading"
              >送信</v-btn>
              <v-btn
                v-else
                color="success"
                large
                disabled
              >送信</v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog 
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      fullscreen>
      <v-card tile>
        <v-toolbar color="grey" flat>
          <v-toolbar-title class="font-weight-light">個人情報の取扱規定</v-toolbar-title>
        </v-toolbar>

        <v-container>
          <v-row class="my-2">
            <v-col cols="12">
              2022年9月制定
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="12">
              1. 個人情報の利用目的について<br>
              個人情報は、当社事業に伴う、人事管理上において必要な範囲で、その取得、利用および提供を行い、目的外の利用は致しません。<br>
              又、当社の管理上において登録者および従業員が業務請負に伴う際に、必要な範囲で、その取得、利用および提供を行い、目的外の利用は致しません。
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="12">
              2. 個人情報の第三者提供について<br>
              収集した個人情報は、委託業務、法律上公的機関への届出･提出が必要な場合、事故等での緊急時を除いて第三者へ提供しません。
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="12">
              3. 個人情報の開示等について<br>
              収集した個人情報の開示、内容の訂正、追加、削除の求めがあった場合には、すみやかに対応します。
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="12">
              4. 個人情報保護について<br>
              個人情報に関する法令および国が定める指針その他の規範を遵守いたします。
            </v-col>
          </v-row>

          <v-row class="mt-5 mb-3" justify="center">
            <v-col cols="2"></v-col>
            <v-col cols="3">
              <v-btn tile depressed block color="grey" @click.stop="checkbox = true; dialog = false;">
                同意する
              </v-btn>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="3">
              <v-btn tile depressed block color="grey" @click.stop="checkbox = false; dialog = false;">
                同意しない
              </v-btn>
            </v-col>
            <v-col cols="2"></v-col>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios"
import employmentInformationAccordion from '../components/EmploymentInformationAccordion.vue'
import forBusinessesAccordion from '../components/ForBusinessesAccordion.vue'
export default {
    components: {
        employmentInformationAccordion,
        forBusinessesAccordion
    },
  data() {
    return {
      name: '',
      email: '',
      phone: null,
      category: '',
      items: ['お仕事', '求人', 'その他'],
      title: '',
      content: '',
      nameRules: [
        v => !!v || 'お名前の入力は必須です',
      ],
      emailRules: [
        v => !!v || 'メールアドレスの入力は必須です',
        v => /.+@.+\..+/.test(v) || '無効メールアドレスです',
      ],
      phoneRules: [
        v => !!v || 'お電話番号の入力は必須です',
        v => /^[0-9]+$/.test(v) || 'お電話番号は半角数字のみで入力して下さい'
      ],
      categoryRules: [
        v => !!v || 'カテゴリの選択は必須です',
      ],
      contentRules: [
        v => !!v || 'お問い合わせ内容の入力は必須です',
        v => v == null || v.length <= 500 || 'お問い合わせ内容は500文字までです。',
      ],
      checkRules: [
        v => !!v || '個人情報の取扱規程は必須です',
      ],
      loading: false, //「送信」ボタンのローディング制御
      //↓アラート関連
      alert: false,
      alert_type: 'success',
      alert_message: '',
      // 個人情報の取り扱い規定
      dialog: false,
      checkbox: false,
    }
  },
  methods: {
    submit() {
      //全てのバリデーションに通過したとき
      if(this.$refs.form.validate()){
          this.loading = true
          try{
            //テーブルの内容をバックエンドに送信
            axios.post('./api/contact.php', {
              name: this.name,
              email: this.email,
              phone: this.phone,
              category: this.category,
              title: this.title,
              content: this.content
            })
            .then((response) => {
              if(response.data.result){
                console.log('メール送信: 成功')
                this.alert_type = 'success'
                this.alert_message = '送信が完了しました!'
                this.alert = true
                this.save_to_db()
                this.$refs.form.reset()
                this.$refs.form.resetValidation()
              } else {
                console.log('メール送信: 失敗')
                this.alert_type = 'error'
                this.alert_message = 'メールの送信に失敗しました'
                this.alert = true  
              }
                this.$vuetify.goTo('#form_top')
                this.loading = false
            })
          }
          catch{
            console.log('DB送信: 接続エラー')
            this.loading = false
            this.alert_type = 'error'
            this.alert_message = '通信エラーが発生しました'
            this.alert = true
            this.$vuetify.goTo('#form_top')

          }
      //バリデーションに通過しないとき
      } else {
          this.alert_type = 'warning'
          this.alert_message = '入力が正しくありません'
          this.alert = true
          this.$vuetify.goTo('#form_top')

      }
    },
    save_to_db() {
      try{
        //テーブルの内容をバックエンドに送信
        axios.post('./api/saveMail.php', {
          name: this.name,
          email: this.email,
          phone: this.phone,
          category: this.category,
          title: this.title,
          content: this.content
        })
        .then((response) => {
          if(response.data.result) {
            console.log('DB送信: 成功')
          } else {
            console.log('DB送信: 失敗')
          }
        })
      }
      catch{
        console.log('DB送信: 接続エラー')
      }
    },
    open_dialog() {
      this.dialog = true;
      this.alert = false;
    }
  },
}
</script>

<style scoped>
.cell {
  border: solid;
  border-top: none;
}

.cell_top {
  border: solid;
}

.remove_border-right {
  border-right: none;
}
</style>